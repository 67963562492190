export const theme = {
  colors: {
    primary: '#f0f0f0',      
    secondary: '#333',      
    background: '#000',      
    textPrimary: '#ffffff',  
    textSecondary: '#000',

    // Палитра для фэнтези
    fantasyPrimary: '#1F4037',   // Тёмно-зелёненький
    fantasySecondary: '#4C4B63', // Серо-фиолетовый
    fantasyAccent: '#D4AF37',    // Золотой
    fantasyNeutral: '#C0C0C0',   // Серебристый

    // Палитра для ужасов
    horrorPrimary: '#8B0000',    // Тёмно-красный
    horrorSecondary: '#000000',  // Чёрный
    horrorAccent: '#2E003E',     // Тёмно-фиолетовый
    horrorNeutral: '#3A3A3A',    // Серый-металлик
  },
  
  fontSize: {
    small: '12px',          
    medium: '14px',         
    large: '16px',         
  },
  spacing: {
    small: '10px',           
    medium: '15px 30px',    
    large: '40px',         
  },
  breakpoints: {
    mobile: '576px',
    tablet: '768px',
    desktop: '1024px',
  },
};

  