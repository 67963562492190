import styled from 'styled-components';
import { theme } from '../theme';

export const AdminWrapper = styled.div`
  width: 90%;
  margin: 50px auto;
  padding: 20px;
  background-color: ${theme.colors.horrorNeutral};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  text-align: center;

  @media (min-width: ${theme.breakpoints.tablet}) {
    max-width: 600px; /* Увеличиваем ширину для планшетов */
    padding: 30px;
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    max-width: 800px; /* Увеличиваем ширину для десктопов */
    padding: 40px;
  }
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid ${theme.colors.fantasyAccent};
  border-radius: 4px;
  font-size: ${theme.fontSize.medium};
`;

export const Button = styled.button`
  padding: 10px;
  background-color: ${theme.colors.fantasyPrimary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: ${theme.fontSize.medium};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${theme.colors.fantasySecondary};
  }
`;
