import React from 'react';
import { observer } from 'mobx-react-lite';

import localeStore from '../stores/localeStore';
import Header from '../components/Header';
import Description from '../components/Description';
import Album from '../components/Album';

const Home: React.FC = observer(() => {
  const { translations } = localeStore;

  return (
    <div>
      <Header />
      <Description />
      <div style={{ paddingTop: '60px' }}>  
        <h1>{translations.fantasyTitle}</h1>
        <p>{translations.fantasyDescription}</p>
      </div>
      <Album />
    </div>
  );
});

export default Home;
