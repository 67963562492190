import styled from 'styled-components';
import { theme } from '../theme'; // Подключаем тему

export const AlbumWrapper = styled.div`
  margin-bottom: 40px;
  padding: 20px;
  background-color: ${theme.colors.background};
  border-radius: 8px;
  text-align: center;

  @media (min-width: ${theme.breakpoints.tablet}) {
    padding: 30px;
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    padding: 40px;
  }
`;

export const AlbumTitle = styled.h2`
  font-size: ${theme.fontSize.large};
  margin-bottom: 20px;

  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: ${theme.fontSize.large};
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    font-size: ${theme.fontSize.large};
  }
`;

export const PhotosWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

export const PhotoItem = styled.div`
  width: 80px;
  height: 120px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fontSize.small};
  color: #777;

  @media (min-width: ${theme.breakpoints.tablet}) {
    width: 100px;
    height: 150px;
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    width: 120px;
    height: 180px;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: ${theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.secondary};
  }

  @media (min-width: ${theme.breakpoints.tablet}) {
    padding: 12px 25px;
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    padding: 15px 30px;
  }
`;
