import React from 'react';
import { DescriptionContainer, DescriptionText } from '../styles/components/descriptionStyles'; 

const Description: React.FC = () => {
  return (
    <DescriptionContainer>
      <DescriptionText>
        Здесь будет описание для страницы. 
      </DescriptionText>
    </DescriptionContainer>
  );
};

export default Description;
