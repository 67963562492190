import React from 'react';
import { observer } from 'mobx-react-lite';
import localeStore from '../stores/localeStore';
import Header from '../components/Header';

const Order: React.FC = observer(() => {
  const { translations } = localeStore;

  return (
    <div>
      <Header />
      <div style={{ paddingTop: '60px' }}> 
        <h1>{translations.orderTitle}</h1>
        <p>{translations.orderDescription}</p>
      </div>
    </div>
  );
});

export default Order;

