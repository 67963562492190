import React from 'react';
import { observer } from 'mobx-react-lite';
import { HeaderContainer, NavMenu, LeftItem, RightItem, CenterMenu, NavItem } from '../styles/components/headerStyles';
import { Link } from 'react-router-dom';
import localeStore from '../stores/localeStore';

const Header: React.FC = observer(() => {
  const { translations } = localeStore; 

  return (
    <HeaderContainer>
      <NavMenu>
      <LeftItem>
          <a href="https://vk.com/horn_mask_shop" target="_blank" rel="noopener noreferrer">
            {translations.vkContact}
          </a>
        </LeftItem>

        <CenterMenu>
          <NavItem>
            <Link to="/">{translations.home}</Link>
          </NavItem>

          <NavItem>
            <Link to="/about">{translations.about}</Link>
          </NavItem>

          <NavItem>
            <Link to="/order">{translations.howToOrder}</Link>
          </NavItem>
        </CenterMenu>

        <RightItem>
        </RightItem>
      </NavMenu>
    </HeaderContainer>
  );
});

export default Header;
