import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: black;
  color: white;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 5px 0;

  @media (min-width: ${theme.breakpoints.tablet}) {
    padding: 10px 0;
  }
 
  @media (min-width: ${theme.breakpoints.desktop}) {
    padding: 15px 0;
  } 

`;

export const NavMenu = styled.ul`
  position: relative;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: ${theme.breakpoints.tablet}) {
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
  } 
`;

export const NavItem = styled.li`
  a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
    transition: transform 0.3s ease;
    display: inline-block;
    font-size: 12px;
    padding: 6px;

    &:hover {
      color: #61dafb;
      transform: scale(1.1);
    }

    @media (min-width: ${theme.breakpoints.tablet}) {
      font-size: 14px;
      padding: 8px;
    }

    @media (min-width: ${theme.breakpoints.desktop}) {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
`;

export const LeftItem = styled(NavItem)`
  position: absolute;
  left: 0;
`;

export const RightItem = styled(NavItem)`
  position: absolute;
  right: 0;
`;

export const CenterMenu = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-grow: 1; 
`;
