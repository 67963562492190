import React from 'react';
import { AlbumWrapper, AlbumTitle, PhotosWrapper, PhotoItem, Button } from '../styles/components/AlbumStyles';
import localeStore from '../stores/localeStore';
import { observer } from 'mobx-react-lite';

const Album: React.FC = observer(() => {
  const { translations } = localeStore;

  // Данные можно загрузить из API или из стора
  const albums = [
    { title: translations.albumTitle, photoCount: 5 },
    { title: translations.albumTitle, photoCount: 3 },
  ];

  return (
    <div>
      {albums.map((album, index) => (
        <AlbumWrapper key={index}>
          <AlbumTitle>{album.title}</AlbumTitle>
          <PhotosWrapper>
            {Array.from({ length: album.photoCount }).map((_, idx) => (
              <PhotoItem key={idx}>ФОТКА</PhotoItem>
            ))}
          </PhotosWrapper>
          <Button>{translations.albumButton}</Button>
        </AlbumWrapper>
      ))}
    </div>
  );
});

export default Album;
