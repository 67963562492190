import { makeAutoObservable } from 'mobx';
import en from '../locales/en';
import ru from '../locales/ru';

class LocaleStore {
  locale: string = 'ru';
  translations = ru; 

  constructor() {
    makeAutoObservable(this);
  }

  setLocale(newLocale: string) {
    this.locale = newLocale;
    this.translations = newLocale === 'en' ? en : ru;
    localStorage.setItem('locale', newLocale);
  }

  loadLocale() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.setLocale(savedLocale);
    }
  }
}

const localeStore = new LocaleStore();
export default localeStore;
