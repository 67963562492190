import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const DescriptionContainer = styled.div`
  position: relative;  
  background-color: ${theme.colors.fantasySecondary}; 
  color: ${theme.colors.textPrimary};
  padding: ${theme.spacing.small}; 
  top: 34px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  border-radius: 8px;

  @media (min-width: ${theme.breakpoints.tablet}) {
    padding: ${theme.spacing.medium};
    top: 52px;
    max-width: 768px;
    border-radius: 12px;
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    padding: ${theme.spacing.large}; 
    top: 68px;
    max-width: 1200px;
    border-radius: 16px;
  }
`;

export const DescriptionText = styled.p`
  font-size: ${theme.fontSize.small}; 
  line-height: 1.6;
  margin: 0;
  color: ${theme.colors.fantasyAccent};

  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: ${theme.fontSize.medium};
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    font-size: ${theme.fontSize.large};
  }
`;
