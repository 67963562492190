import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import localeStore from '../stores/localeStore';
import { AdminWrapper, LoginForm, Input, Button } from '../styles/pages/adminStyles'; 

const Admin: React.FC = observer(() => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    if (username === 'test' && password === '1234') {
      setIsAuthenticated(true);
    } else {
      alert('Invalid login or password');
    }
  };

  return (
    <AdminWrapper>
      <h1>{localeStore.translations.adminTitle}</h1> {/* Локализованный заголовок */}
      
      {!isAuthenticated ? (
        <LoginForm>
          <label>{localeStore.translations.loginLabel}</label>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label>{localeStore.translations.passwordLabel}</label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button onClick={handleLogin}>
            {localeStore.translations.loginButton}
          </Button>
        </LoginForm>
      ) : (
        <>
          <p>Welcome, admin!</p>
        </>
      )}
    </AdminWrapper>
  );
});

export default Admin;
